import React, { useRef } from 'react'
import { CAPTCHA_KEY } from '../../config/config';
import HCaptcha from '@hcaptcha/react-hcaptcha';

const CaptchaVerify = ({token, setToken, captchaMsg}) => {
  const captchaRef = useRef(null);
  const onVerify = (token) => setToken(token);
  const onExpire = () => setToken(null);
  const onError = (err) => console.error('hCaptcha Error:', err);

  return (
    <div className="d-flex flex-column align-items-center justify-content-center px-4 pt-3 rounded-lg text-center">
    <HCaptcha
      sitekey={CAPTCHA_KEY}
      onVerify={onVerify}
      onExpire={onExpire}
      onError={onError}
      ref={captchaRef}
    />
    {token && (
      <p className="text-success font-semibold">CAPTCHA verified successfully!</p>
    )}
    {!token && (
      <p className={captchaMsg ? "text-danger" :"text-secondary"} style={{marginTop: "-3px"}}>Please complete the CAPTCHA to proceed.</p>
    )}
  </div>
  )
}

export default CaptchaVerify