import React from "react";
import UserDropdown from "./DashboardUserDropdown";
import DoughnutChart from "./payoutOverviewChart";
import LinkSection from "./UserLinks";
import { useSelector } from "react-redux";

const RightContentSection = ({ props }) => {
  const currency = useSelector(state => state.user?.selectedCurrency);
  const conversionFactor = useSelector((state) => state.user?.conversionFactor);
  const userSelectedLanguage = useSelector(
    (state) => state.user?.selectedLanguage
  );
  return (
    <>
      <UserDropdown props={props?.userProfile} />
      <DoughnutChart
        pending={props?.payoutDoughnut?.pending}
        approved={props?.payoutDoughnut?.approved}
        paid={props?.payoutDoughnut?.paid}
        payoutPaid={props?.payoutOverview?.payoutPaid}
        currency={currency}
        conversionFactor={conversionFactor}
        userSelectedLanguage={userSelectedLanguage}
      />


      <div className="text-center my-4">
        <div style={{ fontWeight: '900' }}>BUSINESS ENROLLMENT STEPS:</div>
        <br />
        <div style={{ color: '#176AB4' }}>STEP 1</div>
        <div>Scheduke Business on the CALANDAR</div>
        <a href={props?.userProfile?.teamName === "red" ? "https://api.leadconnectorhq.com/widget/bookings/b2b-red" : "https://api.leadconnectorhq.com/widget/bookings/b2b-green"} target="_blank" rel="noopener noreferrer">
          <button style={{ fontWeight: '900', backgroundColor: '#176AB4', color: 'white', padding: '8px 40px', borderRadius: '10px', marginTop: '20px' }}>Calendar</button>
        </a>
        <br />
        <br />
        <div style={{ color: '#176AB4' }}>STEP 2</div>
        <div>Complete Business Signup</div>
        <a href={props?.userProfile?.teamName === "red" ? "https://form.jotform.com/241756522686061" : "https://form.jotform.com/241755723908161"} target="_blank" rel="noopener noreferrer">
          <button style={{ fontWeight: '900', backgroundColor: '#176AB4', color: 'white', padding: '8px 40px', borderRadius: '10px', marginTop: '20px' }}>Submit Business</button>
        </a>
      </div>


      <LinkSection
        payoutTab={props?.payoutOverview}
        replicaLink={props?.replicaLink}
        leadCaptureLink={props?.leadCaptureLink}
        currency={currency}
        conversionFactor={conversionFactor}
      />
    </>
  );
};

export default RightContentSection;
