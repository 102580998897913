// const BASE_URL = "http://192.168.12.88:5002/api/";
// const BASE_URL = "http://162.19.146.134:1688/api/";
const BASE_URL = "https://admin.b2bconnector.biz/api/";

// const BASE_URL = "https://api.wappss.com/";
// const BASE_URL = "https://api.wappss.com/";
// const BASE_URL = "https://api.infinitemlmsoftware.com/";
const DEFAULT_KEY = '14959'


const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"

const CAPTCHA_KEY = "d63cc890-cbc6-4bc6-b63e-5438a22e4940"

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID, CAPTCHA_KEY }
