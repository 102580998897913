import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import DashboardService, { usePostAffiliateEnrollment } from '../../../services/dashboard/Dashboard';

const RegisterFormModal = ({ data }) => {
    const {
        register,
        trigger,
        watch,
        formState: { errors }
    } = useForm();
    const formValues = watch();

    console.log('applYout DAta', data);

    const [activeStep, setActiveStep] = useState(data?.affiliateFormSubmissionStatus ? 5 : 0);
    const [modalShow, setModalShow] = useState(data?.user?.isAffiliate ? false : true);


    const affiliateMutation = usePostAffiliateEnrollment()

    const handleNextSteps = async () => {
        const isValid = await trigger();

        if (!isValid) return
        setActiveStep(activeStep + 1);
    }
    const handlePrevSteps = () => {
        setActiveStep(activeStep - 1);
    }

    const stepsHeading = [
        { name: 'Contact Info', id: 1 },
        { name: 'Banking Info', id: 2 },
        { name: 'W9 Form', id: 3 },
        { name: 'Affiliate Agreement', id: 4 },
        { name: 'Confirmation', id: 5 }
    ]

    const handleSubmit = async () => {
        const isValid = await trigger();
        if (!isValid) return

        affiliateMutation.mutate(formValues, {
            onSuccess: (res) => {
                if (res.status) {
                    setActiveStep(activeStep + 1);
                }
            },
        })


    }

    return (
        <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            // size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="modal-90w"
            centered
        // backdrop="static"
        >
            <Modal.Header closeButton>
                {/* <Modal.Title id="contained-modal-title-vcenter">
        Modal heading
      </Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
                <div className="main-content-regsiter">
                    <div className="row justify-content-center pt-0" id="wizardRow">
                        <div className="col-md-12 text-center">
                            <div className="wizard-form py-2 my-2">
                                <ul id="progressBar" className="progressbar px-0" style={{ fontSize: '14px' }}>
                                    {stepsHeading?.map((step, index) => (
                                        <li
                                            key={`step-${index + 1}`}
                                            id={`progressList-${index + 1}`}
                                            // className={`d-inline-block w-20 position-relative text-center float-start progressbar-list 
                                            //     ${step.id === activeStep ? "active" : ""}
                                            // `}
                                            className={`d-inline-block w-20 position-relative text-center float-start progressbar-list 
                                                ${index + 1 <= activeStep ? "active" : ""}
                                              `}
                                        >
                                            {step.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="form-card">
                            {activeStep === 0 && (
                                <div className='px-4 pb-4 form-card-main' >
                                    <div className='pb-5'>
                                        <h2 className='text-center'>Thank you for taking the next steps to becoming a B2BConnector Affiliate!</h2>
                                        <p className='text-center'>Please take the next 5 minutes to complete this form.</p>
                                    </div>
                                    <div className="d-flex justify-content-center pb-5 mb-4">
                                        <div className="form-group" style={{ width: '500px' }}>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    {...register("affirmation", { required: "You must agree to the terms." })}
                                                />
                                                <span className='ms-2'>
                                                    Under penalty of perjury, I herewith affirm that my electronic signature on this form
                                                    was signed by myself with full knowledge and consent and am legally bound to these
                                                    terms and conditions.
                                                </span>
                                            </label>
                                            {errors.affirmation && (
                                                <p className="error" style={{ color: "red" }}>
                                                    {errors.affirmation.message}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* Contact Info */}
                            {activeStep === 1 && (
                                <div className="row form-card-main" >
                                    <h2 class="fs-title my-3">Contact Information</h2>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="first_name">First name <span className="text-danger">*</span></label>
                                            <input
                                                {...register("first_name", { required: true })}
                                                name="first_name"
                                                id="c_first_name"
                                                className={`form-control ${errors.first_name ? 'border-danger' : ''}`}
                                            />
                                            {errors.first_name && <span className="text-danger">This field is required</span>}
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="last_name">Last Name <span className="text-danger">*</span></label>
                                            <input
                                                {...register("last_name", { required: true })}
                                                name="last_name"
                                                id="c_last_name"
                                                className={`form-control ${errors.last_name ? 'border-danger' : ''}`}
                                            />
                                            {errors.last_name && <span className="text-danger">This field is required</span>}
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="email">Email <span className="text-danger">*</span></label>
                                            <input
                                                {...register("email", { required: true, pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/ })}
                                                type="email"
                                                name="email"
                                                id="c_email"
                                                className={`form-control ${errors.email ? 'border-danger' : ''}`}
                                            />
                                            {errors.email && <span className="text-danger">Please enter a valid email</span>}
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="phone">Phone <span className="text-danger">*</span></label>
                                            <input
                                                {...register("phone", { required: true })}
                                                name="phone"
                                                id="c_phone"
                                                className={`form-control ${errors.phone ? 'border-danger' : ''}`}
                                            />
                                            {errors.phone && <span className="text-danger">This field is required</span>}
                                        </div>
                                    </div>

                                    <div className="col-md-3 mt-2">
                                        <div className="form-group">
                                            <label htmlFor="dob">DOB</label>
                                            <input
                                                {...register("dob")}
                                                type="date"
                                                name="dob"
                                                id="dob"
                                                className={`form-control`}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-3 mt-2">
                                        <div className="form-group">
                                            <label htmlFor="age">Age</label>
                                            <input
                                                {...register("age", { valueAsNumber: true })}
                                                type="number"
                                                name="age"
                                                id="age"
                                                className={`form-control`}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-3 mt-2">
                                        <div className="form-group">
                                            <label htmlFor="gender">Gender <span className="text-danger">*</span></label>
                                            <br />
                                            <div className="mt-2">
                                                <input
                                                    {...register("gender", { required: true })}
                                                    type="radio"
                                                    name="gender"
                                                    value="male"
                                                /> Male
                                                <input
                                                    {...register("gender", { required: true })}
                                                    type="radio"
                                                    name="gender"
                                                    value="female"
                                                    className='ms-2'
                                                /> Female
                                            </div>
                                            {errors.gender && <span className="text-danger">This field is required</span>}
                                        </div>
                                    </div>

                                    <div className="col-md-6 mt-2">
                                        <div className="form-group">
                                            <label htmlFor="address1">Address <span className="text-danger">*</span></label>
                                            <input
                                                {...register("address1", { required: true })}
                                                type="text"
                                                name="address1"
                                                id="c_address1"
                                                className={`form-control ${errors.address1 ? 'border-danger' : ''}`}
                                            />
                                            {errors.address1 && <span className="text-danger">This field is required</span>}
                                        </div>
                                    </div>

                                    <div className="col-md-6 mt-2">
                                        <div className="form-group">
                                            <label htmlFor="c_apt_suit">Apt/Suite</label>
                                            <input
                                                {...register("apt_suit")}
                                                type="text"
                                                name="apt_suit"
                                                id="c_apt_suit"
                                                className={`form-control`}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-3 mt-2">
                                        <div className="form-group">
                                            <label htmlFor="c_city">City <span className="text-danger">*</span></label>
                                            <input
                                                {...register("city", { required: true })}
                                                type="text"
                                                name="city"
                                                id="c_city"
                                                className={`form-control ${errors.city ? 'border-danger' : ''}`}
                                            />
                                            {errors.city && <span className="text-danger">This field is required</span>}
                                        </div>
                                    </div>

                                    <div className="col-md-3 mt-2">
                                        <div className="form-group">
                                            <label htmlFor="c_state">State <span className="text-danger">*</span></label>
                                            <input
                                                {...register("state", { required: true })}
                                                type="text"
                                                name="state"
                                                id="c_state"
                                                className={`form-control ${errors.state ? 'border-danger' : ''}`}
                                            />
                                            {errors.state && <span className="text-danger">This field is required</span>}
                                        </div>
                                    </div>

                                    <div className="col-md-3 mt-2">
                                        <div className="form-group">
                                            <label htmlFor="c_zipcode">Zip Code <span className="text-danger">*</span></label>
                                            <input
                                                {...register("zipcode", { required: true })}
                                                type="text"
                                                name="zipcode"
                                                id="c_zipcode"
                                                className={`form-control ${errors.zipcode ? 'border-danger' : ''}`}
                                            />
                                            {errors.zipcode && <span className="text-danger">This field is required</span>}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* Banking Info */}
                            {activeStep === 2 && (
                                <div class="form-card-main">
                                    <h2 class="fs-title my-3">Banking Information</h2>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="first_name">Account Type {/* <span class="text-danger">*</span> */} (Select
                                                    one):</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="radio" name="accounttype" id="accounttype" value="checking" className='me-1' {...register("accounttype", /* { required: true } */)} />
                                                Checking
                                                <input type="radio" name="accounttype" id="accounttype" value="saving" className='ms-2 me-1'  {...register("accounttype", /* { required: true } */)} />
                                                Saving
                                                {errors.accounttype && <span className="text-danger">This field is required</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="first_name">Name of finance institute {/* <span class="text-danger">*</span> */} </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <p className='text-secondary'>
                                                    (Street Address)
                                                </p>
                                                <input type="text" name="finace_name" id="finace_name" className={`form-control ${errors.finace_name ? "border-danger" : ''}`} {...register("finace_name", /* { required: true } */)} />
                                                {errors.finace_name && <span className="text-danger">This field is required</span>}
                                                <p className='text-secondary'>
                                                    (City,State,Zip code)
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="first_name">Bank Routing Number {/* <span class="text-danger">*</span> */}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" name="routing_no" id="routing_no" className={`form-control ${errors.routing_no ? "border-danger" : ''}`} {...register("routing_no", /* { required: true } */)} />
                                                {errors.routing_no && <span className="text-danger">This field is required</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="first_name">Account Number {/* <span class="text-danger">*</span> */} </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" name="ac_number" id="ac_number" className={`form-control ${errors.ac_number ? "border-danger" : ''}`} {...register("ac_number", /* { required: true } */)} />
                                                {errors.ac_number && <span className="text-danger">This field is required</span>}
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <input type="text" name="ac_signature" id="ac_signature" className={`form-control ${errors.ac_signature ? "border-danger" : ''}`} {...register("ac_signature", /* { required: true } */)} />
                                                {errors.ac_signature && <span className="text-danger">This field is required</span>}
                                                <p class="text-center mt-2 text-secondary">
                                                    Signature -
                                                    09-04-2024
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="first_name">Percentage to be deposite into be this account {/* <span class="text-danger">*</span> */} </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="number" id="dep_percentage" name="dep_percentage" className={`form-control ${errors.dep_percentage ? "border-danger" : ''}`} {...register("dep_percentage", /* { required: true } */)} />
                                                {errors.dep_percentage && <span className="text-danger">This field is required</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* W9 Form */}
                            {activeStep === 3 && (
                                <div class="form-card-main">
                                    <h2 class="fs-title">W9 Form </h2>
                                    <p class=" my-2 text-center text-success d-block d-sm-none"> On mobile devices, double tap the form to enlarge view. </p>
                                    <div class="container bg-light heightpluxscollable" style={{ height: "500px" }}>
                                        <div class="row">
                                            <div class="col-md-12  bg-white">
                                                <div class="row">
                                                    <div class="col-md-12 border-dark border-bottom">
                                                        <div class="row  ">
                                                            <div class="col-md-3 border-dark border-right  font-10">
                                                                <p class="font-16 poppins-bold text-center  pt-1 mb-0">Form W-9
                                                                    (Rev. March 2024)
                                                                    Department of the Treasury
                                                                    Internal Revenue Service
                                                                </p>
                                                            </div>
                                                            <div class="col-md-6 border-dark border-right">
                                                                <h3 class="font-16 poppins-bold text-center   pt-1 mb-0">
                                                                    Request for Taxpayer</h3>
                                                                <h3 class="font-16 poppins-bold text-center   pt-1 mb-0">
                                                                    Identification
                                                                    Number and Certification.</h3>
                                                                <p class="font-10 text-center">Go to www.irs.gov/FormW9 for
                                                                    instructions
                                                                    and the latest information</p>

                                                            </div>
                                                            <div class="col-md-3 text-center">
                                                                <h3 class="font-16 poppins-bold text-center   pt-1 mb-0">
                                                                    Give form to the
                                                                    requester. Do not
                                                                    send to the IRS.
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 border-top border-dark">
                                                        <div class="row">
                                                            <div class="col-md-1 border-dark border-right position-relative">
                                                                <p class="font-10 d-sm-block d-none" style={{ transform: 'rotate(-90deg)', fontSize: '7px', position: 'absolute', top: '210px' }}>
                                                                    <b>Print or type. See Specific Instructions on page 3.</b>
                                                                </p>
                                                            </div>
                                                            <div class="col-md-11">
                                                                <div className="row">
                                                                    <div className="col-md-12 border-bottom border-dark">
                                                                        <p className="font-10 mb-0">1. Name of entity/individual</p>
                                                                        <input
                                                                            type="text"
                                                                            className={`w-100 ${errors.w9?.entityOrIndividualName ? 'border-danger' : 'border-1'} bg-light`}
                                                                            {...register("w9.entityOrIndividualName", /* { required: true } */)}
                                                                        />
                                                                    </div>

                                                                    <div className="col-md-12 border-bottom border-dark">
                                                                        <p className="font-10 mb-0">2. Business name/disregarded entity name</p>
                                                                        <input
                                                                            type="text"
                                                                            className={`w-100 ${errors.w9?.businessName ? 'border-danger' : 'border-1'} bg-light`}
                                                                            {...register("w9.businessName", /* { required: true } */)}
                                                                        />
                                                                    </div>

                                                                    <div className="col-md-12 m-0 p-0">
                                                                        <div class="row m-0 border-dark border-top border-left border-bottom">
                                                                            <div class="col-md-8 p-0 border-dark border-right">
                                                                                <div class="border-dark p-2 border">
                                                                                    <p class="font-10 text-dark">3a Check the
                                                                                        appropriate
                                                                                        box for federal tax
                                                                                        classification of the entity/individual
                                                                                        whose name
                                                                                        is
                                                                                        entered on line 1. Check
                                                                                        only one of the following seven boxes. </p>

                                                                                    <div class="d-flex justify-content-between flex-wrap">
                                                                                        <div class="font-10 text-dark">
                                                                                            <input type="checkbox" name="w9_q_3a" value="Individual/sole" {...register("w9.individual")} />
                                                                                            Individual/sole
                                                                                        </div>
                                                                                        <div class="font-10 text-dark">
                                                                                            <input type="checkbox" name="w9_q_3a" value="proprietor" {...register("w9.proprietor")} /> proprietor
                                                                                        </div>
                                                                                        <div class="font-10 text-dark">
                                                                                            <input type="checkbox" name="w9_q_3a" value="C corporation" {...register("w9.c_corporation")} /> C
                                                                                            corporation
                                                                                        </div>
                                                                                        <div class="font-10 text-dark">
                                                                                            <input type="checkbox" name="w9_q_3a" value="S corporation" {...register("w9.s_corporation")} /> S
                                                                                            corporation
                                                                                        </div>
                                                                                        <div class="font-10 text-dark">
                                                                                            <input type="checkbox" name="w9_q_3a" value="Partnership" {...register("w9.partnership")} /> Partnership
                                                                                        </div>
                                                                                        <div class="font-10 text-dark">
                                                                                            <input type="checkbox" name="w9_q_3a" value="Trust/estate" {...register("w9.trust")} /> Trust/estate

                                                                                        </div>

                                                                                    </div>
                                                                                    <div>
                                                                                        <div class="font-10 text-dark">
                                                                                            <input type="checkbox" name="w9_q_3a" id="w9_q_3a" value="LLC" {...register("w9.llc")} /> LLC. Enter
                                                                                            the tax
                                                                                            classification (C = C corporation, S = S
                                                                                            corporation, P
                                                                                            = Partnership) . . . .
                                                                                            Note: Check the “LLC” box above and, in
                                                                                            the
                                                                                            entry space,
                                                                                            enter the appropriate code (C, S, or P)
                                                                                            for the
                                                                                            tax
                                                                                            classification of the LLC, unless it is
                                                                                            a
                                                                                            disregarded
                                                                                            entity. A disregarded entity should
                                                                                            instead
                                                                                            check the
                                                                                            appropriate
                                                                                            box for the tax classification of its
                                                                                            owner.

                                                                                        </div>
                                                                                        <div class="font-10">
                                                                                            <input type="checkbox" value="other" {...register("w9.other")} /> Other (see
                                                                                            instructions)
                                                                                            <input type="text" name="w9_q_3a_detail" id="w9_q_3a_detail" class="border-1 w-100 bg-light" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="border-dark border-top p-2">
                                                                                    <p class="font-10 text-dark">3b If on line 3a
                                                                                        you
                                                                                        checked “Partnership” or “Trust/estate,” or
                                                                                        checked
                                                                                        “LLC” and entered “P” as its tax
                                                                                        classification,
                                                                                        and you are providing this form to a
                                                                                        partnership,
                                                                                        trust, or estate in which you have an
                                                                                        ownership
                                                                                        interest, check
                                                                                        this box if you have any foreign partners,
                                                                                        owners,
                                                                                        or beneficiaries. See instructions . .
                                                                                        <input type="checkbox" name="w9_q_3b" id="w9_q_3b" value="checked" {...register("w9.3b")} />
                                                                                    </p>



                                                                                </div>

                                                                            </div>
                                                                            <div class="col-md-4">
                                                                                <p class="font-10">4 Exemptions (codes apply only
                                                                                    to
                                                                                    certain entities, not individuals;
                                                                                    see instructions on page 3):</p>
                                                                                <input type="text" name="w9_q_4" id="w9_q_4" class="w-100 border-1 bg-light" {...register("w9.exemptions")} />
                                                                                <p class="font-10">Exempt payee code (if any)</p>
                                                                                <input type="text" name="w9_q_4a" id="w9_q_4a" class="w-100 border-1 bg-light" {...register("w9.payee_code")} />
                                                                                <p class="font-10">Exemption from Foreign Account
                                                                                    Tax
                                                                                    Compliance Act (FATCA) reporting
                                                                                    code (if any)</p>
                                                                                <input type="text" name="w9_q_4b" id="w9_q_4b" class="w-100 border-1 bg-light" {...register("w9.FATCA")} />
                                                                                <p class="font-10 text-center">(Applies to accounts
                                                                                    maintained
                                                                                    outside the United States.)
                                                                                </p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row m-0 border-dark border-top border-left border-bottom">
                                                                            <div className="col-md-7 border-dark border p-2">
                                                                                <p className="font-10 mb-0">5. Address</p>
                                                                                <input
                                                                                    type="text"
                                                                                    className="w-100 border-1 bg-light"
                                                                                    {...register("w9.address")}
                                                                                />
                                                                                <p className="font-10 mb-0">City, state, and ZIP code</p>
                                                                                <input
                                                                                    type="text"
                                                                                    className="w-100 border-1 bg-light"
                                                                                    {...register("w9.cityStateZip")}
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-5">
                                                                                <p className="font-10 mb-0">Requester’s name and address</p>
                                                                                <input
                                                                                    type="text"
                                                                                    className="w-100 border-1 bg-light"
                                                                                    {...register("w9.requesterName")}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="row m-0 border-dark border-top border-left border-bottom">
                                                                            <div className="col-md-12">
                                                                                <p className="font-10 mb-0">7. List account number(s)</p>
                                                                                <input
                                                                                    type="text"
                                                                                    className="w-100 border-1 bg-light"
                                                                                    {...register("w9.accountNumbers")}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="row border-dark border">
                                                    <div class="col-md-2 bg-black text-white font-10">
                                                        <p class="font-10 text-white pt-1 mb-0">Part I</p>
                                                    </div>
                                                    <div class="col-md-10">
                                                        <h3 class="font-10 pt-1">
                                                            Taxpayer Identification Number (TIN)
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-8 font-10">
                                                        <p>
                                                            Enter your TIN in the appropriate box. The TIN provided must match the name given on line 1 to avoid backup withholding. For individuals, this is generally your social security number (SSN). However, for a resident alien, sole proprietor, or disregarded entity, see the instructions for Part I, later. For other entities, it is your employer identification number (EIN). If you do not have a number, see How to get a TIN, later.
                                                        </p>
                                                        <p>
                                                            Note: If the account is in more than one name, see the instructions for line 1. See also What Name and Number To Give the Requester for guidelines on whose number to enter.
                                                        </p>
                                                    </div>
                                                    <div className="col-md-4 px-0">
                                                        <p className="border poppins-bold border-dark font-10">
                                                            Social security number
                                                        </p>
                                                        <div className="d-flex">
                                                            <div className="d-flex">
                                                                {[1, 2, 3].map((i) => (
                                                                    <input
                                                                        key={`ssn-${i}`}
                                                                        type="text"
                                                                        maxLength="1"
                                                                        {...register(`w9.ssn[${i - 1}]`)}
                                                                        className="border-dark barcode"
                                                                    />
                                                                ))}
                                                            </div>
                                                            <span className="separator">-</span>
                                                            <div className="d-flex">
                                                                {[4, 5, 6].map((i) => (
                                                                    <input
                                                                        key={`ssn-${i}`}
                                                                        type="text"
                                                                        maxLength="1"
                                                                        {...register(`w9.ssn[${i - 1}]`)}
                                                                        className="border-dark barcode"
                                                                    />
                                                                ))}
                                                            </div>
                                                            <span className="separator">-</span>
                                                            <div className="d-flex">
                                                                {[7, 8, 9, 10].map((i) => (
                                                                    <input
                                                                        key={`ssn-${i}`}
                                                                        type="text"
                                                                        maxLength="1"
                                                                        {...register(`w9.ssn[${i - 1}]`)}
                                                                        className="border-dark barcode"
                                                                    />
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <p>or</p>
                                                        <p className="border poppins-bold border-dark font-10">
                                                            Employer identification number
                                                        </p>
                                                        <div className="mb-1 d-flex">
                                                            <div className="d-flex">
                                                                {[1, 2, 3].map((i) => (
                                                                    <input
                                                                        key={`ein-${i}`}
                                                                        type="text"
                                                                        maxLength="1"
                                                                        {...register(`w9.ein[${i - 1}]`)}
                                                                        className="border-dark barcode"
                                                                    />
                                                                ))}
                                                            </div>
                                                            <span className="separator">-</span>
                                                            <div className="d-flex">
                                                                {[4, 5, 6].map((i) => (
                                                                    <input
                                                                        key={`ein-${i}`}
                                                                        type="text"
                                                                        maxLength="1"
                                                                        {...register(`w9.ein[${i - 1}]`)}
                                                                        className="border-dark barcode"
                                                                    />
                                                                ))}
                                                            </div>
                                                            <span className="separator">-</span>
                                                            <div className="d-flex">
                                                                {[7, 8, 9, 10].map((i) => (
                                                                    <input
                                                                        key={`ein-${i}`}
                                                                        type="text"
                                                                        maxLength="1"
                                                                        {...register(`w9.ein[${i - 1}]`)}
                                                                        className="border-dark barcode"
                                                                    />
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row  border-dark border">
                                                    <div class="col-md-2 bg-black text-white font-10">
                                                        <p class="font-10 text-white pt-1 mb-0">Part II</p>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <h3 class="font-10 pt-1">
                                                            Certification
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12    font-10">
                                                        <p class="py-2">
                                                            Under penalties of perjury, I certify that: <br />
                                                            1. The number shown on this form is my correct taxpayer identification
                                                            number
                                                            (or I am waiting for a number to be issued to me); and<br />
                                                            2. I am not subject to backup withholding because (a) I am exempt from
                                                            backup
                                                            withholding, or (b) I have not been notified by the Internal Revenue<br />
                                                            Service (IRS) that I am subject to backup withholding as a result of a
                                                            failure
                                                            to report all interest or dividends, or (c) the IRS has notified me that
                                                            I am
                                                            no longer subject to backup withholding; and<br />
                                                            3. I am a U.S. citizen or other U.S. person (defined below); and<br />
                                                            4. The FATCA code(s) entered on this form (if any) indicating that I am
                                                            exempt
                                                            from FATCA reporting is correct.<br />
                                                            Certification instructions. You must cross out item 2 above if you have
                                                            been
                                                            notified by the IRS that you are currently subject to backup
                                                            withholding<br />
                                                            because you have failed to report all interest and dividends on your tax
                                                            return.
                                                            For real estate transactions, item 2 does not apply. For mortgage
                                                            interest
                                                            paid,<br />
                                                            acquisition or abandonment of secured property, cancellation of debt,
                                                            contributions to an individual retirement arrangement (IRA), and,
                                                            generally,
                                                            payments<br />
                                                            other than interest and dividends, you are not required to sign the
                                                            certification, but you must provide your correct TIN. See the
                                                            instructions for
                                                            Part II, later.<br />

                                                        </p>

                                                    </div>
                                                </div>
                                                <div class="row  border-dark border">
                                                    <div class="col-md-2   border-dark border font-10">
                                                        <p class="font-10   pt-1 mb-0">Sign
                                                            Here</p>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <h3 class="font-10 pt-1 ">
                                                            Signature of
                                                            U.S. person
                                                        </h3>
                                                    </div>
                                                    <div class="col-md-8 text-center">
                                                        <h3 class="font-10 text-center ">
                                                            Date
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div class="row border-dark border-top border-bottom">
                                                    <div class="col-md-6   font-10">
                                                        <h4>
                                                            General Instructions

                                                        </h4>
                                                        <p class="font-10">
                                                            Section references are to the Internal Revenue Code unless otherwise
                                                            noted. <br />
                                                            Future developments. For the latest information about developments
                                                            related to Form W-9 and its instructions, such as legislation enacted
                                                            after they were published, go to www.irs.gov/FormW9.
                                                        </p>
                                                        <h4>
                                                            General Instructions

                                                        </h4>
                                                        <p class="font-10">
                                                            What’s New
                                                            <br />
                                                            Line 3a has been modified to clarify how a disregarded entity completes
                                                            this line. An LLC that is a disregarded entity should check the
                                                            appropriate box for the tax classification of its owner. Otherwise, it
                                                            should check the “LLC” box and enter its appropriate tax classification
                                                        </p>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <p class="font-10 ">
                                                            New line 3b has been added to this form. A flow-through entity is
                                                            required to complete this line to indicate that it has direct or
                                                            indirect
                                                            foreign partners, owners, or beneficiaries when it provides the Form W-9
                                                            to another flow-through entity in which it has an ownership interest.
                                                            This
                                                            change is intended to provide a flow-through entity with information
                                                            regarding the status of its indirect foreign partners, owners, or
                                                            beneficiaries, so that it can satisfy any applicable reporting
                                                            requirements. For example, a partnership that has any indirect foreign
                                                            partners may be required to complete Schedules K-2 and K-3. See the
                                                            Partnership Instructions for Schedules K-2 and K-3 (Form 1065)
                                                        </p>
                                                        <h4>
                                                            General Instructions

                                                        </h4>
                                                        <p class="font-10">
                                                            Purpose of Form
                                                            <br />
                                                            An individual or entity (Form W-9 requester) who is required to file an
                                                            information return with the IRS is giving you this form because they
                                                        </p>
                                                    </div>

                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 text-black text-right">
                                                        Cat. No. 10231X
                                                    </div>
                                                    <div class="col-md-6 text-black  text-right">
                                                        Form W-9 (Rev. 3-2024)
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div className="col-md-12 mt-2">
                                                <img src="https://form.b2bconnector.biz/assets/img/page2.png" className="w-100 img-thumbnail imagePreview" data-src="https://form.b2bconnector.biz/assets/img/page2.png" alt="Image 2" style={{ cursor: 'pointer' }} />
                                            </div>
                                            <div className="col-md-12 mt-2">
                                                <img src="https://form.b2bconnector.biz/assets/img/page3.png" className="w-100 img-thumbnail imagePreview" data-src="https://form.b2bconnector.biz/assets/img/page3.png" alt="Image 3" style={{ cursor: 'pointer' }} />
                                            </div>
                                            <div className="col-md-12 mt-2">
                                                <img src="https://form.b2bconnector.biz/assets/img/page4.png" className="w-100 img-thumbnail imagePreview" data-src="https://form.b2bconnector.biz/assets/img/page4.png" alt="Image 4" style={{ cursor: 'pointer' }} />
                                            </div>
                                            <div className="col-md-12 mt-2">
                                                <img src="https://form.b2bconnector.biz/assets/img/page5.png" className="w-100 img-thumbnail imagePreview" data-src="https://form.b2bconnector.biz/assets/img/page5.png" alt="Image 5" style={{ cursor: 'pointer' }} />
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            )}
                            {/* Affiliate Agreement */}
                            {activeStep === 4 && (
                                <div class="form-card-main">
                                    <h2 class="fs-title">Affiliate Agreement</h2>
                                    <p>Scroll down the bottom of this agreement and complete your information, then press Confirm
                                        and Save.</p>
                                    <p class=" my-2 text-center text-success d-block d-sm-none"> On mobile devices, double tap the form to enlarge view. </p>

                                    <div className="container bg-light heightpluxscollable " style={{ border: '1px solid lightblue', padding: '15px', height: '500px' }}>















                                        <div class="row">
                                            <div class="col-md-12 mt-2">
                                                {/* <img src="https://form.b2bconnector.biz/assets/img/formaff/page-1.png" class="w-100 img-thumbnail imagePreview" data-src="https://form.b2bconnector.biz/assets/img/formaff/page-1.png" alt="Image 1" style={{ cursor: 'pointer' }} /> */}
                                                <div className="bg-white p-0 p-md-5">
                                                    <h1 className="text-center py-2 aff-heading" style={{ textAlign: 'justify' }}>Independent Affiliate Marketing Channel Agreement</h1>
                                                    <p style={{ textAlign: 'justify' }}>By executing THIS AGREEMENT, the Contractor
                                                        applies for legal authorization
                                                        to become a B2B Connector Independent Affiliate Marketing Channel (AMC)
                                                        and enter into contract with B2B Connector hereinafter “Company” and the
                                                        Contractor hereinafter “AMC”, (Company and Contractor may be referred to
                                                        herein as a “Party” or collectively as the “Parties.”) have read,
                                                        understand, and
                                                        agreed to all terms set forth in this Agreement. </p>

                                                    <p style={{ textAlign: 'justify' }}><b> WHEREAS</b>, the company B2B Connector helps
                                                        connect small businesses together
                                                        by providing lead generation and marketing to fill the calendars for
                                                        certified
                                                        representatives to show these small businesses how to save money and create
                                                        new
                                                        opportunities for their companies to grow. The two Verticals are
                                                        Healthcare/Wellness and Business Lending.</p>
                                                    <p style={{ textAlign: 'justify' }}><b> WHEREAS</b>, AMC desires to obtain the right
                                                        from the Company to represent
                                                        Products and Services, hereinafter "Services"; for the sole purpose of
                                                        securing
                                                        qualified appointments. A qualified appointment is defined in the Company’s
                                                        AMC
                                                        onboarding and training process. </p>

                                                    <h1 className="text-center   py-2 aff-heading">Section One</h1>
                                                    <p style={{ textAlign: 'justify' }}><b> NOW, THEREFORE,</b> in consideration of the
                                                        mutual covenants herein contained, the
                                                        Parties hereto agree as follows: </p>
                                                    <h2 className="text-left py-2  aff-sub-heading">1. Appointment</h2>
                                                    <p style={{ textAlign: 'justify' }}>Upon the terms and conditions of this Agreement,
                                                        the Company hereby appoints
                                                        AMC as a non-exclusive agent of the Company, and AMC hereby accepts such
                                                        appointment. </p>
                                                    <h2 className="text-left py-2 aff-sub-heading">2. Payment For Service</h2>
                                                    <p style={{ textAlign: 'justify' }}>Payments for services under this Agreement shall
                                                        be defined in Exhibit A, attached
                                                        hereto. Exhibit A shall define the fees the Company shall pay AMC for the
                                                        performance of services under this Agreement. Company reserves the right to
                                                        amend, change, or cancel the payments for service at any time. </p>
                                                </div>
                                            </div>
                                            {/* <img src="https://form.b2bconnector.biz/assets/img/formaff/page-2.png" class="w-100 img-thumbnail imagePreview" data-src="https://form.b2bconnector.biz/assets/img/formaff/page-2.png" alt="Image 1" style={{ cursor: 'pointer' }} /> */}
                                            <div class="col-md-12 mt-2">
                                                <div class="bg-white p-0 p-md-5">
                                                    <p style={{ textAlign: "justify" }}>To support our comprehensive suite of
                                                        services—including advanced marketing
                                                        tools, cutting-edge affiliate software, and tailored CRM solutions—B2B
                                                        CONNECTOR
                                                        will apply a standard fee of 3% to the total amount due for payout. This fee
                                                        ensures
                                                        that we can continue delivering exceptional value and resources that
                                                        contribute to
                                                        the ongoing success of each AMC. </p>

                                                    <p style={{ textAlign: "justify" }}>This 3% fee will be deducted monthly from the
                                                        total payout amount. It reflects our
                                                        commitment to providing top-tier marketing support and technology, essential
                                                        for
                                                        driving your growth and achieving outstanding results.
                                                    </p>
                                                    <h2 class="py-2 text-left aff-sub-heading">3. Term</h2>
                                                    <p style={{ textAlign: "justify" }}>This Agreement shall be effective as of the
                                                        Effective Date defined as the original
                                                        electronic records of the submitted Agreement and shall automatically renew,
                                                        annually on the anniversary of the Effective Date for an indefinite period
                                                        of time, or
                                                        until the Agreement is terminated by either Party, pursuant to Section 4 of
                                                        this
                                                        Agreement. </p>

                                                    <h2 class="py-2 text-left aff-sub-heading">4. Termination</h2>
                                                    <p style={{ textAlign: "justify" }}><b> 4.1. Termination without Cause.</b> Either
                                                        Party may terminate this Agreement,
                                                        without cause, at any time by giving written, thirty (30) days advance
                                                        notice to the
                                                        other Party</p>

                                                    <p style={{ textAlign: "justify" }}><b>4.2. Written Notice </b> may constitute any
                                                        electronic transmission such as email or
                                                        SMS message. However, electronic communication must be accompanied by a
                                                        written letter, mailed within seven (7) days of the electronic
                                                        communication.
                                                    </p>

                                                    <p style={{ textAlign: "justify" }}>A. The Company may terminate this Agreement
                                                        immediately for “apt cause",
                                                        which shall include: Any action of gross neglect or willful misconduct by
                                                        AMC
                                                        which is materially and demonstrably injurious to the Company.</p>
                                                    <ul class="text-black">
                                                        <li>AMC’s material violation of any of the Company's policies or procedures.
                                                        </li>
                                                        <li>AMC’s conviction, plea of nolo contendere, guilty plea, or confession
                                                            to, an
                                                            act of fraud, misappropriation or embezzlement or any crime punishable
                                                            as a felony or any other crime that involves moral turpitude.</li>
                                                        <li>AMC’s material breach of this Agreement.</li>
                                                    </ul>

                                                    <h2 class="py-2 text-left aff-sub-heading">4.3 Effects Of Termination</h2>
                                                    <p style={{ textAlign: "justify" }}>a. Immediately upon either termination of this
                                                        Agreement, AMC shall:</p>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-2">
                                                {/* <img src="https://form.b2bconnector.biz/assets/img/formaff/page-3.png" class="w-100 img-thumbnail imagePreview" data-src="https://form.b2bconnector.biz/assets/img/formaff/page-3.png" alt="Image 1" style={{ cursor: 'pointer' }} /> */}
                                                <div class="bg-white p-0 p-md-5">

                                                    <ul class="text-black">
                                                        <li>AMC’s material violation of any of the Company's policies or procedures.
                                                        </li>
                                                        <li>AMC’s conviction, plea of nolo contendere, guilty plea, or confession
                                                            to, an
                                                            act of fraud, misappropriation or embezzlement or any crime punishable
                                                            as a felony or any other crime that involves moral turpitude.</li>
                                                        <li>AMC’s material breach of this Agreement.</li>
                                                    </ul>
                                                    <p style={{ textAlign: "justify" }}><b> 4.4. Forfeiture of Commissions upon
                                                        Termination for Cause.</b> In the event of the
                                                        immediate termination of this Agreement by the Company for just cause, any
                                                        payments not yet earned by AMC up to and including the date of termination
                                                        of this
                                                        Agreement and not yet paid by the Company, as well as, any and all future
                                                        payments.</p>
                                                    <p style={{ textAlign: "justify" }}>4.5. Payments are considered earned by the AMC
                                                        when all the account agreements
                                                        and contracts have been signed and executed by the account representative
                                                        and an
                                                        enrollment and implementation date scheduled with the account.
                                                    </p>
                                                    <h2 class="py-2 text-left aff-sub-heading">5. Relationship of Parties</h2>
                                                    <p style={{ textAlign: "justify" }}><b> 5.1. Independent Contractor.</b> AMC agrees
                                                        to perform the Services hereunder
                                                        solely as an independent contractor of the Company. The Parties to this
                                                        Agreement
                                                        recognize that this Agreement does not create any actual or apparent agency,
                                                        partnership, franchise, or relationship of employer and employee between the
                                                        Parties. AMC is not authorized to enter into or commit the Company to any
                                                        agreements. AMC shall provide the Services as an independent contractor and
                                                        shall
                                                        not act as an employee or officer of the Company.</p>
                                                    <p style={{ textAlign: "justify" }}><b> 5.2. Representation.</b> AMC shall have the
                                                        right hereunder to represent that he or
                                                        she is an "Authorized AMC" of the Company and Products. Any other
                                                        characterization, either stated or implied, shall be prohibited under this
                                                        Agreement.</p>
                                                    <p style={{ textAlign: "justify" }}><b> 5.3. Benefits.</b> AMC shall not be entitled
                                                        to participate in any of the Company's
                                                        benefits, including without limitation any health or retirement plans. AMC
                                                        shall not
                                                        be entitled to any remuneration, benefits, or expenses other than as
                                                        specifically
                                                        provided for in this Agreement.</p>
                                                    <p style={{ textAlign: "justify" }}><b>5.4. Taxes. </b> The Company shall not be
                                                        liable for taxes, Worker's Compensation,
                                                        unemployment insurance, employers' liability, employer's FICA, social
                                                        security,
                                                        withholding tax, or other taxes or withholding for or on behalf of the
                                                        Affiliate or any </p>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-2">
                                                {/* <img src="https://form.b2bconnector.biz/assets/img/formaff/page-4.png" class="w-100 img-thumbnail imagePreview" data-src="https://form.b2bconnector.biz/assets/img/formaff/page-4.png" alt="Image 1" style={{ cursor: 'pointer' }} /> */}
                                                <div class="bg-white p-0 p-md-5">
                                                    <p style={{ textAlign: "justify" }}>other person consulted or employed by the AMC in
                                                        performing Services under this
                                                        Agreement. All such costs shall be AMC's responsibility.
                                                    </p>
                                                    <h2 class="py-2 text-left aff-sub-heading">6. Products</h2>

                                                    <p style={{ textAlign: "justify" }}>6.1. <b> Changes to Products.</b> The Company
                                                        may, at its discretion, make changes to
                                                        Products without notice to AMC. Such changes may impact the required
                                                        services
                                                        provided by AMC. AMC agrees to comply with all such changes in a timely
                                                        fashion. -
                                                        flesh out </p>

                                                    <p style={{ textAlign: "justify" }}>6.2. <b> Promotional Materials.</b> AMC shall
                                                        not alter or revise the content, formatting,
                                                        wording or terms and conditions of any promotional documents, forms,
                                                        documents, or components either in print or digital format used by the
                                                        Company in
                                                        delivering or promoting Products.</p>
                                                    <h2 class="py-2 text-left aff-sub-heading">7. Territory and Accounts</h2>
                                                    <p style={{ textAlign: "justify" }}> Under this agreement AMC is authorized to
                                                        represent Products without geographic
                                                        restrictions. Active commercial accounts that have been secured by AMC shall
                                                        be
                                                        protected by the Company for the benefit of AMC for as long as this
                                                        Agreement
                                                        shall remain in force. Accounts are considered secured upon the submission
                                                        of a
                                                        complete and in order Detailed Payroll Report. Complete Detailed Payroll
                                                        Reports
                                                        are defined in Company’s sales and marketing materials.</p>
                                                    <h2 class="py-2 text-left aff-sub-heading">8. Duties of Independent Affiliate Marketing Channel
                                                    </h2>
                                                    <p style={{ textAlign: "justify" }}> AMC, without limitation, agrees to:</p>
                                                    <p style={{ textAlign: "justify" }}> 8.1.<b> Professional Conduct.</b> Act
                                                        professionally, honestly and ethically and to not
                                                        engage in any activities that would tend to harm the Company, other AMC’s,
                                                        or
                                                        commercial accounts.</p>
                                                    <p style={{ textAlign: "justify" }}> 8.2. <b>Marketing Efforts to Secure Qualified
                                                        Appointments.</b> Exert ethical efforts
                                                        to promote, market Products so as to acquire qualified appointments with
                                                        accounts. Qualified accounts are any business with more than 10 employees
                                                        who
                                                        have a defined annual income outlined in Company’s onboarding and training
                                                        process.</p>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-2">
                                                {/* <img src="https://form.b2bconnector.biz/assets/img/formaff/page-5.png" class="w-100 img-thumbnail imagePreview" data-src="https://form.b2bconnector.biz/assets/img/formaff/page-5.png" alt="Image 1" style={{ cursor: 'pointer' }} /> */}
                                                <div class="bg-white p-0 p-md-5">
                                                    <p style={{ textAlign: "justify" }}> 8.3<b> Account Management.</b> ALL Accounts are
                                                        managed and maintained by the
                                                        company and are no longer the responsibility of the AMC once the program has
                                                        been implemented.
                                                    </p>
                                                    <p style={{ textAlign: "justify" }}> 8.4. <b> Accurate Representations.</b> Not make
                                                        any statement of fact, representation,
                                                        guarantee, or warranty, in written or verbal form, that does not accurately
                                                        represent the Products or the Company.
                                                    </p>
                                                    <p style={{ textAlign: "justify" }}>8.5.<b> Communication with the
                                                        Company.</b>Notify the Company of all requests by
                                                        prospective accounts for communication, webinars, contracts, and materials
                                                        in a
                                                        timely manner.
                                                    </p>
                                                    <p style={{ textAlign: "justify" }}> 8.6.<b>Compliance with Policies.</b> Comply
                                                        with all policies and procedures set forth
                                                        by the Company.

                                                    </p>
                                                    <p style={{ textAlign: "justify" }}> 8.7.<b>Approved Promotional Materials</b> Use
                                                        only promotional material either
                                                        digital or in print that has been created or pre-approved by the Company.
                                                    </p>
                                                    <h2 class="py-2 text-left aff-sub-heading">9. Duties of the Company
                                                    </h2>
                                                    <p style={{ textAlign: "justify" }}>The Company, without limitation, agrees to</p>

                                                    <p style={{ textAlign: "justify" }}> 9.1.<b>Marketing and Technical Materials.</b>
                                                        Provide access to marketing and
                                                        technical information, brochures, sales tools, instructional materials,
                                                        advertising
                                                        literature and other Product data in a digital format to sufficiently
                                                        facilitate
                                                        marketing activities.

                                                    </p>
                                                    <p style={{ textAlign: "justify" }}>9.2. <b>Communication and Materials
                                                        Requests.</b> Respond to requests for
                                                        communication, webinars, contracts and materials in a timely manner.

                                                    </p>
                                                    <p style={{ textAlign: "justify" }}>9.3.<b> Product Updates.</b> Provide current
                                                        information as to improvements,
                                                        upgrades, or other changes in the Products.

                                                    </p>
                                                    <p style={{ textAlign: "justify" }}> 9.4.<b> Commission Payments.</b> Make timely
                                                        payments of commissions as called for
                                                        under this Agreement.

                                                    </p>
                                                    <p style={{ textAlign: "justify" }}> 9.5.<b> Product Quality.</b> Maintain the
                                                        highest standard of Product development and
                                                        fulfill to the full extent as represented in the Company literature.
                                                    </p>
                                                    <p style={{ textAlign: "justify" }}> 9.6.<b> Training and Sales Support.</b>Provide
                                                        adequate training and sales support
                                                        regarding Products.
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-2">
                                                {/* <img src="https://form.b2bconnector.biz/assets/img/formaff/page-6.png" class="w-100 img-thumbnail imagePreview" data-src="https://form.b2bconnector.biz/assets/img/formaff/page-6.png" alt="Image 1" style={{ cursor: 'pointer' }} /> */}
                                                <div class="bg-white p-0 p-md-5">
                                                    <h2 class="py-2 text-left aff-sub-heading">10. Intellectual Property</h2>
                                                    <p style={{ textAlign: "justify" }}>AMC acknowledges that the Company's trademarks,
                                                        service marks, trade names,
                                                        patents, copyrighted materials, and other intellectual property are owned
                                                        exclusively by the Company or its affiliated partners. Use of such
                                                        intellectual
                                                        property by AMC shall be in compliance with this Agreement. Further,
                                                        trademarks,
                                                        service marks, trade names, patents and copyrighted materials and other
                                                        intellectual property owned by the Company's AMC’s shall be protected under
                                                        the
                                                        terms and provisions of this Agreement.</p>
                                                    <h2 class="py-2 text-left aff-sub-heading">11. Confidentiality</h2>
                                                    <p style={{ textAlign: "justify" }}>As used in this Section, "Confidential
                                                        Information" shall mean any proprietary
                                                        information, technical data, trade secrets, or know-how, including, but not
                                                        limited
                                                        to, research, product plans, products, services, customer lists, markets,
                                                        software,
                                                        developments, source and object codes, inventions, processes, formulas,
                                                        technology, designs, drawings, engineering, hardware configuration
                                                        information,
                                                        marketing, pricing, finances, or other business information.
                                                    </p>
                                                    <p style={{ textAlign: "justify" }}>
                                                        AMC shall not disclose any Confidential Information to any person, firm, or
                                                        corporation except in strict accordance with this Agreement. AMC agrees to
                                                        use his
                                                        or her best efforts to keep the Confidential Information confidential. Any
                                                        use or
                                                        disclosure of Confidential Information beyond the uses allowed by this
                                                        Agreement
                                                        shall require the written authorization of the Company. In addition, the
                                                        legal
                                                        obligation of the AMC to protect and hold in strictest confidence the
                                                        Confidential
                                                        Information shall extend to the Confidential Information of the Company's
                                                        vendors
                                                        and associate companies.
                                                    </p>
                                                    <h2 class="py-2 text-left aff-sub-heading">12. Force Majeure</h2>
                                                    <p style={{ textAlign: "justify" }}>
                                                        The Company is not responsible for damages or delay in performance caused by
                                                        acts of God, strikes, lockouts, accidents, pandemics, or other events beyond
                                                        the
                                                        control of the Company.
                                                    </p>
                                                    <h2 class="py-2 text-left aff-sub-heading">13. Indemnification</h2>
                                                    <p style={{ textAlign: "justify" }}>
                                                        13.1. <b> Indemnification by AMC.</b> AMC agrees to indemnify and hold
                                                        harmless the
                                                        Company, its parents, subsidiaries and AMC, and their shareholders,
                                                        officers,
                                                        Affiliates, employees and directors, from and against any and all claims,
                                                        demands,
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-2">
                                                {/* <img src="https://form.b2bconnector.biz/assets/img/formaff/page-7.png" class="w-100 img-thumbnail imagePreview" data-src="https://form.b2bconnector.biz/assets/img/formaff/page-7.png" alt="Image 1" style={{ cursor: 'pointer' }} /> */}
                                                <div class="bg-white p-0 p-md-5">
                                                    <p style={{ textAlign: "justify" }}>liabilities, losses, damages, costs or expenses,
                                                        including but not limited to, court
                                                        costs and attorneys' fees, asserted against, or suffered or incurred by, any
                                                        of them
                                                        by reason of, arising out of, resulting from or in any way connected with,
                                                        directly or
                                                        indirectly, AMC's (i) activities as an AMC, including without limitation,
                                                        any
                                                        unauthorized representations made by Affiliate, (ii) breach of the terms of
                                                        this
                                                        Agreement, or (iii) violation of or failure to comply with any applicable
                                                        federal, state
                                                        or local law or regulation, whether or not litigation is commenced.</p>
                                                    <p style={{ textAlign: "justify" }}>13.2. <b> Indemnification by Company.</b> The
                                                        Company agrees to indemnify and hold
                                                        harmless AMC, its parents, subsidiaries and affiliates, and their
                                                        shareholders,
                                                        officers, employees and directors, from and against any and all claims,
                                                        demands,
                                                        liabilities, losses, damages, costs or expenses, including but not limited
                                                        to, court
                                                        costs and attorneys' fees, asserted against, or suffered or incurred by, any
                                                        of them
                                                        by reason of, arising out of, resulting from or in any way connected with,
                                                        directly or
                                                        indirectly, the Company's (i) activities, including without limitation, any
                                                        unauthorized representations made by the Company, (ii) breach of the terms
                                                        of this
                                                        Agreement or (iii) violation of or failure to comply with any applicable
                                                        federal, state
                                                        or local law or regulation, whether or not litigation is commenced.</p>
                                                    <h2 class="py-2 text-left aff-sub-heading">14. Waiver</h2>
                                                    <p style={{ textAlign: "justify" }}>The failure of the AMC or the Company to insist
                                                        on strict performance of any
                                                        covenant or obligation under this Agreement, regardless of the length of
                                                        time for
                                                        which such failure continues, shall not be deemed a waiver of such right to
                                                        demand
                                                        strict compliance in the future. No consent or waiver, expressed or implied,
                                                        to or of
                                                        any breach or default in the performance of any obligation under this
                                                        Agreement
                                                        shall constitute a consent or waiver to or of any other breach or default in
                                                        the
                                                        performance of the same or any other obligation</p>

                                                    <h2 class="py-2 text-left aff-sub-heading">15. Enforceability</h2>
                                                    <p style={{ textAlign: "justify" }}>If under any applicable law or rule of any
                                                        applicable jurisdiction, any provision of
                                                        this Agreement is held to be invalid or unenforceable, the remainder of this
                                                        Agreement will be interpreted as best to effect the intent of the Parties
                                                        hereto. The
                                                        remaining provisions of this Agreement shall remain in full force and effect
                                                        and
                                                        shall not be affected by the illegal, invalid or unenforceable provision or
                                                        by its
                                                        severance from this Agreement.</p>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-2">
                                                {/* <img src="https://form.b2bconnector.biz/assets/img/formaff/page-8.png" class="w-100 img-thumbnail imagePreview" data-src="https://form.b2bconnector.biz/assets/img/formaff/page-8.png" alt="Image 1" style={{ cursor: 'pointer' }} /> */}
                                                <div class="bg-white p-0 p-md-5">
                                                    <h2 class="py-2 text-left aff-sub-heading">16. Arbitration</h2>
                                                    <p style={{ textAlign: "justify" }}>This Agreement is governed under the laws of the
                                                        State of Nevada. Except as
                                                        expressly set forth herein, the Parties agree that in the event a dispute
                                                        arises
                                                        between them as to their respective rights, duties and obligations under
                                                        this
                                                        Agreement, such disputes shall be resolved by binding arbitration under the
                                                        Commercial Rules of the American Arbitration Association with arbitration to
                                                        occur
                                                        within the county of Reno in Nevada.</p>

                                                    <p style={{ textAlign: "justify" }}>There shall be one arbitrator who shall be an
                                                        attorney who shall have expertise in
                                                        business law transactions. Each Party to the arbitration shall be
                                                        responsible for its
                                                        own costs and expenses of arbitration, including legal and filing fees. The
                                                        arbitrator
                                                        shall have the right in his or her discretion to authorize the obtaining of
                                                        discovery,
                                                        including the taking of depositions of witnesses for the purposes of
                                                        discovery. The
                                                        presentations of Parties in the arbitration proceeding shall be commenced
                                                        and
                                                        completed within 60 days after the selection of the arbitrator and the
                                                        arbitrator
                                                        shall render his or her decision in writing within 30 days after the
                                                        completion of
                                                        such presentations. The decision of the arbitrator shall be final and
                                                        binding on the
                                                        Parties. An arbitration award may be enforced in any court of competent
                                                        jurisdiction. At the request of AMC or the Company, the arbitrator shall
                                                        make and
                                                        provide to the Parties written findings of fact and conclusions of law. This
                                                        agreement to arbitrate shall survive any termination or expiration of this
                                                        Agreement.</p>

                                                    <p style={{ textAlign: "justify" }}>Notwithstanding the foregoing, the arbitrator
                                                        shall have no jurisdiction over
                                                        disputes relating to the ownership, validity, use or registration of any
                                                        mark,
                                                        copyright or other intellectual property or proprietary rights of the
                                                        Company,
                                                        without the Company's prior written consent. The Company may seek any
                                                        applicable remedy in any applicable forum with respect to such disputes.</p>

                                                    <p style={{ textAlign: "justify" }}>Nothing in this section shall prevent a Party
                                                        from terminating this Agreement or
                                                        from applying to and obtaining from any court having jurisdiction a writ of
                                                        attachment, a temporary injunction, preliminary injunction and/or other
                                                        equitable relief available to safeguard and protect its interests prior to
                                                        the filing
                                                        of or during or following any arbitration or other proceeding or pending the
                                                        handing down of a decision or award in connection with any arbitration or
                                                        other
                                                        proceeding.
                                                    </p>
                                                    <p style={{ textAlign: "justify" }}>Nothing contained herein shall be deemed to give
                                                        the arbitrator any authority, </p>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-2">
                                                <div class="bg-white b-0 p-0 p-md-5">

                                                    <p style={{ textAlign: "justify" }}>power or right to alter, change, amend, modify,
                                                        add to or to subtract from any of
                                                        the provisions of this Agreement.
                                                    </p>
                                                    <h1 class="py-2 text-center aff-heading">Section Two
                                                    </h1>
                                                    <h2 class="py-2 text-left aff-sub-heading">1. Attorney’s Fees
                                                    </h2>

                                                    <p style={{ textAlign: "justify" }}>If any legal action is necessary to enforce this
                                                        Agreement, the prevailing Party
                                                        shall be entitled to reasonable attorney’s fees and expenses in addition to
                                                        any
                                                        other allowable relief</p>
                                                    <h2 class="py-2 text-left aff-sub-heading">2. Complete Understanding and Modification</h2>
                                                    <p style={{ textAlign: "justify" }}>This Agreement and the attached exhibits
                                                        constitute the full and complete
                                                        understanding and agreement of the Parties relating to the subject matter
                                                        hereof
                                                        and supersede all prior understandings and agreements relating to such
                                                        subject
                                                        matter. No amendments, variations, modifications, supplements, waivers or
                                                        changes in this Agreement shall be binding upon any Party hereto unless set
                                                        forth
                                                        in a document duly executed by or on behalf of both Parties.
                                                    </p>
                                                    <h2 class="py-2 text-left aff-sub-heading">3. Headings
                                                    </h2>
                                                    <p style={{ textAlign: "justify" }}>Headings used in this Agreement are provided for
                                                        convenience only and shall not
                                                        be used to construe meaning or intent.
                                                    </p>
                                                    <h2 class="py-2 text-left aff-sub-heading">Counterparts
                                                    </h2>
                                                    <p style={{ textAlign: "justify" }}>This Agreement may be executed in two or more
                                                        counterparts each of which
                                                        will be deemed an original, but together will constitute one and the same
                                                        instrument. A signed copy or facsimile of this Agreement may be relied upon
                                                        as
                                                        an original for all purposes. </p>
                                                    <h2 class="py-2 text-left aff-sub-heading">4. Notices

                                                    </h2>
                                                    <p style={{ textAlign: "justify" }}>Any notice, request, demand, waiver, consent,
                                                        approval or other communication
                                                        required to be given pursuant to this Agreement (each, a “Notice”) shall be
                                                        in
                                                        writing and shall be deemed given: (i) upon delivery, if by hand; (ii) after
                                                        three (3)
                                                        business days, if sent by express mail or air courier. All Notices are to be
                                                        made to
                                                        the Parties at the addresses appearing below </p>

                                                </div>
                                            </div>
                                            <div class="col-md-12 bg-white mt-2 mx-2">
                                                <div class="p-100">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <h4>Company:</h4>
                                                            <p>B2B CONNECTOR LLC</p>
                                                            <p>401 Ryland Street</p>
                                                            <p>STE 200-A</p>
                                                            <p>Reno, NV 89502</p>
                                                            <p>IN WITNESS WHEREOF, the Parties have executed this Agreement as of
                                                                the Start
                                                                Date
                                                                recorded in the electronic timestamp of the submission of the
                                                                Agreement.</p>
                                                            <h4>B2B Connector LLC</h4>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <img class="img-fluid" src="https://form.b2bconnector.biz/assets/img/formaff/page-9-sign.png" alt="" />
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <p> Name: Michael Choma</p>
                                                            <p>Title: President &amp; CEO</p>
                                                            <p>Date: 7/1/2024</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="signature-section py-3" id="signature-section">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group py-3">
                                                                            <label htmlFor="af_signatore_name">
                                                                                Signature:<span className="text-danger">*</span>
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                id="af_signatore_name"
                                                                                className={`${errors.affiliate?.signature ? 'border-danger' : ''}`}
                                                                                {...register('affiliate.signature', { required: true })}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="form-group py-3">
                                                                            <label htmlFor="af_name">
                                                                                Name:<span className="text-danger">*</span>
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                id="af_name"
                                                                                className={`${errors.affiliate?.signature ? 'border-danger' : ''}`}
                                                                                {...register('affiliate.name', { required: true })}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group py-3">
                                                                            <label htmlFor="af_company">
                                                                                Company:
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                id="af_company"
                                                                                {...register('affiliate.company')}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group py-3">
                                                                            <label htmlFor="af_title">
                                                                                Title:
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                id="af_title"
                                                                                {...register('affiliate.title')}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group py-3">
                                                                            <label htmlFor="af_date">
                                                                                Date:<span className="text-danger">*</span>
                                                                            </label>
                                                                            <input
                                                                                type="date"
                                                                                id="af_date"
                                                                                className={`${errors.affiliate?.signature ? 'border-danger' : ''}`}
                                                                                {...register('affiliate.date', { required: true })}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <h3>If No Company or Title, Just Leave Blank.</h3>
                                                            <p class=" p-2 text-black"><span class="bg-warning">Important: Please
                                                                scroll
                                                                down the page and initialize item 3.3 below.</span></p>
                                                            <div class="text-center">
                                                                <img src="https://form.b2bconnector.biz/assets/img/formaff/page-9-arrow.png" class="img-fluid" alt="" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-2">
                                                {/* <img src="https://form.b2bconnector.biz/assets/img/formaff/page-10.png" class="w-100 img-thumbnail imagePreview" data-src="https://form.b2bconnector.biz/assets/img/formaff/page-10.png" alt="Image 10" style={{ cursor: 'pointer' }} /> */}
                                                <div class="bg-white p-0 p-md-5">
                                                    <h1 class="py-2 text-center aff-heading">Exhibit A: Payments</h1>
                                                    <h2 class="py-2 text-left aff-sub-heading">1. Payments: Vital110
                                                    </h2>

                                                    <p style={{ textAlign: "justify" }}> <b> 1.1 Direct Marketing Appointment Fee:</b>
                                                        Unless specifically agreed to otherwise,
                                                        during the term of this Agreement, AMC shall be entitled to receive a
                                                        one-time <b> $250
                                                            direct marketing fee on the revenue received by Company for each
                                                            enrolled
                                                            employee during the initial enrollment</b> in the Vital 110 program
                                                        which is sold by
                                                        the Company through qualified commercial account appointments acquired by
                                                        AMC
                                                        under this Agreement as a result of their marketing and referral efforts.
                                                    </p>

                                                    <p style={{ textAlign: "justify" }}><b> 1.2 Direct Marketing Appointment Fee: 50%
                                                        Matching Bonus.</b> Unless specifically
                                                        agreed to otherwise, during the term of this Agreement, AMC shall be
                                                        entitled to
                                                        receive a one-time <b>$125 direct marketing fee on the revenue received by
                                                            Company for each enrolled employee during the initial enrollment </b>in
                                                        the Vital
                                                        110 program which is sold by the Company through qualified commercial
                                                        account
                                                        appointments acquired by AMC under this Agreement as a result of their
                                                        marketing
                                                        and referral efforts. AMC is paid only on their First Level Referral. </p>
                                                    <h2 class="py-2 text-left aff-sub-heading">2. Payments: BizLending360
                                                    </h2>
                                                    <p style={{ textAlign: "justify" }}><b> 2.1 Direct Marketing Appointment Fee:</b>
                                                        Unless specifically agreed to otherwise,
                                                        during the term of this Agreement, AMC shall be entitled to receive a
                                                        one-time<b> 40%
                                                            Net Profit direct marketing fee on Either a Working Capital Deal or
                                                            Equipment
                                                            Financing Transaction.</b> in the BizLending360 program which is sold by
                                                        the
                                                        Company through qualified commercial account appointments acquired by AMC
                                                        under this Agreement as a result of their marketing and referral efforts.
                                                    </p>
                                                    <p style={{ textAlign: "justify" }}><b> 2.2 Direct Marketing Appointment Fee: 50%
                                                        Matching Bonus. </b>Unless specifically
                                                        agreed to otherwise, during the term of this Agreement, AMC shall be
                                                        entitled to
                                                        receive a one-time <b>20% Net Profit direct marketing fee on Either a
                                                            Working
                                                            Capital Deal or Equipment Financing Transaction.</b> in the
                                                        BizLending360 program
                                                        which is sold by the Company through qualified commercial account
                                                        appointments
                                                        acquired by AMC under this Agreement as a result of their marketing and
                                                        referral
                                                        efforts. AMC is paid only on their First Level Referral. </p>
                                                </div>
                                            </div>
                                            <div class="col-md-12 bg-white mx-2 mt-2">
                                                <div class="bg-white p-0 p-md-5">
                                                    <h1 class="text-center aff-heading">Section Three
                                                    </h1>
                                                    <p style={{ textAlign: "justify" }}><b> 1.1 Fees Earned</b>: Fees earned by AMC’s
                                                        shall be paid on a monthly basis. No fees are
                                                        earned, due, or payable until the Company has received payment from the
                                                        subscribing commercial account. Our Monthly Cutoff is the 15th of each
                                                        month. We
                                                        will send out payments on the first Friday of each new month. </p>
                                                    <p style={{ textAlign: "justify" }}>1.2 <b> Payment of Fees As Earned: Vital110:</b>
                                                        Company shall pay any fees earned on an
                                                        “AS EARNED BASIS” over the course of a 12 month period for any account with
                                                        more
                                                        than 10 enrolled employees. This is to protect Company and AMC from any
                                                        potential
                                                        chargebacks. Payments will be made in 12 equal installments beginning the
                                                        first
                                                        month the Company receives the initial revenue from the Vital 110 product
                                                        sold by
                                                        the Company. The Company reserves the right to review and adjust the account
                                                        employee minimum of 10 enrolled employees at its sole discretion.
                                                    </p>
                                                    <p style={{ textAlign: "justify" }} id="signature-section-initial">1.3 <b>Payment
                                                        of Fees As Earned: BizLending360:</b> Company shall pay any fees
                                                        earned on an “as earned basis” B2B Connector shall be payable within five
                                                        (5) days
                                                        after the equipment is accepted by the lessee in writing and the vendor
                                                        paid. If this
                                                        happens before the 15th of the month, AMC Funds will go out that first
                                                        Friday of the
                                                        new month. However, should any transaction submitted by B2B Connector/ AMC
                                                        and accepted and funded by BizLending360., and for which AMC has been paid
                                                        compensation be placed in default for any reason within the first (90) days
                                                        after
                                                        funding, AMC agrees to return to B2B Connector. within ten (10) days of a
                                                        written
                                                        demand any and all commissions and expenses paid to it. AMC Must: Initial
                                                        <input
                                                            type="text"
                                                            id="af_bizlending360_initiliazed"
                                                            name="af_bizlending360_initiliazed"
                                                            className={`${errors.affiliate?.biz ? 'border-danger' : ''}`}
                                                            {...register('affiliate.biz', { required: true })}
                                                        />


                                                    </p>

                                                    <h5 class="py-2 text-left aff-sub-heading">2. Payments: BizLending360
                                                    </h5>
                                                    <p style={{ textAlign: "justify" }}>Expenses incurred by AMC in the performance of
                                                        this Agreement shall be the sole
                                                        responsibility of AMC unless other specific expense reimbursement terms have
                                                        been
                                                        agreed to by the Company in writing. </p>
                                                    <h5 class="py-2 text-left aff-sub-heading">3. Taxes

                                                    </h5>
                                                    <p style={{ textAlign: "justify" }}>AMC is solely responsible for the payment of all
                                                        taxes such as: income, social security,
                                                        employment-related, or other taxes incurred as a result of the bonuses
                                                        distributed
                                                        under this Agreement and for all filings, obligations, reports, and timely
                                                        notifications
                                                        relating to such taxes. </p>

                                                </div>
                                            </div>

                                            <div class="col-md-12 bg-white mx-2 mt-2">
                                                <div class="bg-white p-0 p-md-5">
                                                    <p style={{ textAlign: "justify" }}>4. Fee Structure for B2B CONNECTOR Services To
                                                        support our comprehensive suite
                                                        of services—including advanced marketing tools, cutting-edge affiliate
                                                        software, and
                                                        tailored CRM solutions—B2B CONNECTOR will apply a standard fee of 3% to the
                                                        total
                                                        amount due for payout. This fee ensures that we can continue delivering
                                                        exceptional
                                                        value and resources that contribute to the ongoing success of each AMC. This
                                                        3% fee
                                                        will be deducted monthly from the total payout amount. It reflects our
                                                        commitment
                                                        to providing top-tier marketing support and technology, essential for
                                                        driving your
                                                        growth and achieving outstanding results. </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* Confirmation */}
                            {activeStep === 5 && (
                                <div class="form-card-main">
                                    <h2 class="fs-title">Confirmation</h2>
                                    <div class="my-4 text-center">
                                        <h4 class="text-black my-3" id="firstnamespan">{formValues.first_name}</h4>
                                        <h4 class="text-black my-3">Thank you for completing your Affiliate Enrollment.
                                        </h4>
                                        <p class="text-center text-black">Our staff reviews and approves new accounts within the same business day.
                                        </p>
                                        <p class="text-center text-black">Make it a great day!
                                        </p>
                                        <p class="text-center text-black">Your Support Team
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {activeStep !== 5 && activeStep !== 0 && activeStep !== 1 && <button style={{ backgroundColor: '#616161', color: 'white', fontWeight: 'bold', padding: '5px 10px', borderRadius: '5px' }} onClick={handlePrevSteps} /* disabled={activeStep === 0} */>Previous</button>}
                {activeStep === 4 ?
                    <button style={{ backgroundColor: 'skyblue', color: 'white', fontWeight: 'bold', padding: '5px 10px', borderRadius: '5px' }} variant="primary" onClick={handleSubmit} disabled={affiliateMutation.status === "loading"}>
                        {affiliateMutation.status === "loading" ? 'Loading...'
                            : 'Confirm & Save'}</button>
                    :
                    activeStep !== 5 &&
                    <button style={{ backgroundColor: 'skyblue', color: 'white', fontWeight: 'bold', padding: '5px 10px', borderRadius: '5px' }} onClick={handleNextSteps}>{activeStep === 2 || activeStep === 3 ? 'Skip/Next' : 'Next'}</button>
                }
            </Modal.Footer>


            <style>
                {`
          .margin-left {
              margin-left: -15px;
              font-weight: 600;
          }
          .margin-left-10 {
              margin-left: -10px;
              font-weight: 600;
          }
          .barcode {
              width: 20px;
          }
          #grad1 {
              background-color: #9C27B0;
              background-image: linear-gradient(120deg, #FF4081, #81D4FA);
          }
          #msform {
              text-align: center;
              position: relative;
              margin-top: 20px;
          }
          #msform fieldset .form-card {
              background: white;
              border: 1px solid lightgray;
              border-radius: 10px;
              padding: 20px 40px 30px 40px;
              box-sizing: border-box;
              width: 94%;
              margin: 0 3% 20px 3%;
              position: relative;
          }
          #msform fieldset {
              background: white;
              border: 0 none;
              border-radius: 0.5rem;
              box-sizing: border-box;
              width: 100%;
              margin: 0;
              padding-bottom: 20px;
              position: relative;
          }
          #msform fieldset .form-card {
              text-align: left;
              color: #9E9E9E;
          }
          label {
              color: black;
          }
          #msform .action-button {
              width: 100px;
              background: skyblue;
              font-weight: bold;
              color: white;
              border: 0 none;
              border-radius: 0px;
              cursor: pointer;
              padding: 10px 5px;
              margin: 10px 5px;
          }
          #msform .action-button:hover,
          #msform .action-button:focus {
              box-shadow: 0 0 0 2px white, 0 0 0 3px skyblue;
          }
          #msform .action-button-previous {
              width: 100px;
              background: #616161;
              font-weight: bold;
              color: white;
              border: 0 none;
              border-radius: 0px;
              cursor: pointer;
              padding: 10px 5px;
          }
          #msform .action-button-previous:hover,
          #msform .action-button-previous:focus {
              box-shadow: 0 0 0 2px white, 0 0 0 3px #616161;
          }
          .heightpluxscollable {
              height: 400px;
              overflow: auto;
          }
          select.list-dt {
              border: none;
              outline: 0;
              border-bottom: 1px solid #ccc;
              padding: 2px 5px 3px 5px;
              margin: 2px;
          }
          select.list-dt:focus {
              border-bottom: 2px solid skyblue;
          }
          .card {
              z-index: 0;
              border: none;
              border-radius: 0.5rem;
              position: relative;
          }
          .fs-title {
              font-size: 25px;
              color: lightblue;
              margin-bottom: 10px;
              font-weight: bold;
              text-align: left;
          }
          #progressbar {
              margin-bottom: 30px;
              overflow: hidden;
              color: lightgrey;
          }
          #progressbar .active {
              color: #000000;
          }
          #progressbar li {
              list-style-type: none;
              font-size: 12px;
              width: 20%;
              float: left;
              position: relative;
          }
          #progressbar li:last-child {
              width: 0%;
          }
          #progressbar #account:before {
              font-family: FontAwesome;
              content: "1";
          }
          #progressbar #banking:before {
              font-family: FontAwesome;
              content: "2";
          }
          #progressbar #w9form:before {
              font-family: FontAwesome;
              content: "3";
          }
          #progressbar #Affiliate:before {
              font-family: FontAwesome;
              content: "4";
          }
          #progressbar #Confirmation:before {
              font-family: FontAwesome;
              content: "5";
          }
          #progressbar li:before {
              width: 50px;
              height: 50px;
              line-height: 45px;
              display: block;
              font-size: 18px;
              color: #ffffff;
              border-radius: 50%;
              background: white;
              color: lightgray;
              border: 1px solid lightgray;
              padding: 2px;
              text-align: center;
          }
          #progressbar li:after {
              content: '';
              width: 100%;
              height: 2px;
              background: lightgray;
              position: absolute;
              left: 0;
              top: 25px;
              z-index: -1;
              text-align: center;
          }
          #progressbar li.active:before,
          #progressbar li.active:after {
              background: white;
              color: skyblue;
              border: 1px solid skyblue;
          }
          .radio-group {
              position: relative;
              margin-bottom: 25px;
          }
          .radio {
              display: inline-block;
              width: 204px;
              height: 104px;
              border-radius: 0;
              background: lightblue;
              box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
              box-sizing: border-box;
              cursor: pointer;
              margin: 8px 2px;
          }
          .radio:hover {
              box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
          }
          .radio.selected {
              box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
          }
          .fit-image {
              width: 100%;
              object-fit: cover;
          }
          .font-10 {
              font-size: 10px;
          }
          .border-danger-imp {
              border: 1px solid red !important;
          }
        `}
            </style>

        </Modal>
    )
}

export default RegisterFormModal